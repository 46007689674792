*,
*::before,
*::after {
  box-sizing: border-box;
  color: currentColor;
}

* {
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body {
  overflow-y: auto;
}

aside,
article,
footer,
header,
main,
nav,
section {
  display: block;
}

img {
  border: 0;
  border-style: none;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

[disabled] {
  cursor: not-allowed;
}

:root {
  --defaultCopy: calc((22 / 16) * 1rem); /* default */
  --h0: calc((64 / 16) * 1rem);
  --h1: calc((42 / 16) * 1rem);
  --h2: calc((36 / 16) * 1rem);
  --font-weight-default: 400;
  --font-weight-bold: 700;
  --font-height-default: 1.4;
  --font-height-s: 1.15;
}

body {
  font-size: calc((18 / 16) * 1rem);
  font-size: var(--defaultCopy);
  font-weight: 400;
  font-weight: var(--font-weight-default);
  line-height: 1.4;
  line-height: var(--font-height-default);
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-family: "Quicksand", sans-serif;
  text-rendering: optimizeLegibility;
  text-align: left;
}

.font-serif {
  font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida,
    DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.h0,
h1,
h2 {
  font-size: 44px;
  font-weight: var(--font-weight-bold);
  line-height: var(--font-height-s, 1.15);
}
@media (orientation: landscape) {
  .h0,
  h1,
  h2 {
    font-size: 64px;
    font-size: var(--h0);
    font-weight: var(--font-weight-bold);
    line-height: var(--font-height-s, 1.15);
  }
}

.h1 {
  font-size: calc((42 / 16) * 1rem);
  font-size: var(--h1);
}
.h2 {
  font-size: calc((36 / 16) * 1rem);
  font-size: var(--h2);
}

.badge-number {
  font-size:25px;
}

b,
strong {
  font-weight: bold;
}

.font-number {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

a,
a:visited,
a:hover {
  color: currentColor;
  background-color: transparent;
}

a > * {
  pointer-events: none;
}

a,
.touch-no-delay {
  touch-action: manipulation;
  letter-spacing: 1px;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
  outline: 1px auto -webkit-focus-ring-color;
}

.flex {
  display: flex;
}

.col {
  flex-direction: column;
  display: flex;
}

.flex-grid {
  flex-wrap: wrap;
}

.row-gap > *:not(:last-child) {
  --row-child-margin: 6px;
  margin-right: var(--row-child-margin);
}
.col-gap > *:not(:last-child) {
  --column-child-margin: 6px;
  margin-bottom: var(--column-child-margin);
}

.main-start {
  justify-content: flex-start;
}

.main-end {
  justify-content: flex-end;
}

.main-center {
  justify-content: center;
}

.main-around {
  justify-content: space-around;
}

.main-between {
  justify-content: space-between;
}

.cross-center {
  align-items: center;
}

.cross-start {
  align-items: flex-start;
}

.cross-end {
  align-items: flex-end;
}

.cross-baseline {
  align-items: baseline;
}


@media (orientation: landscape) {
  .underline {
    text-decoration: underline;
    -webkit-text-decoration: currentColor solid underline;
    text-decoration: currentColor solid underline;
  }
  .underline-double {
    text-decoration: underline;
    -webkit-text-decoration: currentColor wavy underline;
    text-decoration: currentColor wavy underline;
  }
}

@media (orientation: portrait) {
  .landscape-only {
    display: none !important;
  }
}

.offscreen {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

:root {
  --max: 520px;
  --min: 320px;
  --shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);
  --shadow-l: 0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsla(0, 0%, 0%, 0.12);
  --shadow-xl: 0 10px 20px hsla(0, 0%, 0%, 0.15), 0 3px 6px hsla(0, 0%, 0%, 0.1);
}

body,
html {
  background-color: #be9fef;
  color: #894fe4;
}

header {
   font-weight: 700;
  font-weight: var(--font-weight-bold);
  max-width: 540px;
  text-shadow: 0px .6px #894fe4;
  color:#480ba9;
  text-align: center;
  padding: 8vh 0;
}
main {
  max-width: var(--max, 520px);
  margin-left: auto;
  margin-right: auto;
}


footer {
  display: flex;
  justify-content: center;
  padding: 12vh 0;
}
footer a:first-of-type {
  padding-top: 8px;
}
footer a:not(:last-of-type){
  margin-right: 12px;
}
footer svg {
  filter:drop-shadow(0 1px 0 #894fe4);
}
footer path {
  transition: filter 0.1s ease-out;
}
footer a:hover path {
  filter:brightness(1.3);
}


article {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  width: 100%;
  text-shadow: 0px 1px #894fe4;
  text-align: center;
  padding: 10vh 0;
  transition: color 0.1s 0.3s ease-in-out;
}

article.done {
  color: #480ba9;
}
article > p {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  max-width: var(--min, 260px);
  text-shadow: 0px .6px #894fe4;
}
article p a:hover {
  filter:brightness(1.3);
}
article > ul {
  list-style: none;
  display: grid;
  grid-gap: 20px 20px;
  width: 100%;
  max-width: var(--min, 260px);
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: repeat(4, 1fr);
}

article img,video {
  box-shadow: 0px 1px 2px rgb(72,11,169,.4);
}

article > ul li > img + img {
  margin-top: 20px;
}

#fff img, #ads ul img,#games ul img {
  width:120px;
  height:104px;
  object-fit: cover;
  object-position: center;
   z-index: 9!important;
}
#ads ul img {
  width:64px;
  height:91px;
}
#games ul img {
  width:65px;
  height:54px;
}
#influence ul {
  min-height: 194px;
}
#ads ul {
  max-width:400px;
  grid-template-columns: repeat(5, 1fr);
}

#fff ul {
  /* max-width:400px; */
  grid-template-columns: repeat(2, 1fr);
}

video {
  max-height: 597px;
}

.video-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 56.25% 0 0 0;
  position: relative;
}
.video-container video,
.video-container embed,
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
