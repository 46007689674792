.progress-container {
  position: fixed;
  top: calc(50% - 110px);
  right: 6px;
  width: 80px;
  z-index: 10;
  /* border:2px solid #480ba9; */
  /* border-radius: 8px; */
}
[progress-section] a {
  width: 80px;
  min-height: 44px;
  transition: all 0.2s ease-in;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none!important;
}

[progress-section] a:hover {
  filter: brightness(1.1);
}

 @media (orientation: landscape) {
  [progress-section-container] {
    top: 50%;
    transform: translateY(-50%);
  }
  [progress-section] a::before {
    /* content: attr(data-tooltip); */
    content: "Scroll to";
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in;
    position: absolute;
    left: -84px;
    top: 1px;
    width: 120px;
    height: 40px;
    line-height: 40px;

  }

  [progress-section] a:hover::before {
    opacity: 1 !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
} 

[progress-section~="achieved"] {
  filter:brightness(1);
}

[spinner] {
  position: relative;
  display: inline-flex;
  width: 24px;
  height: 24px;
  overflow: hidden;
  text-indent: -999em;
  background-color: #894fe4;
  border-radius: 50%;
  animation-name: spinner;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spinner {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
